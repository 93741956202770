import React from 'react';
import { Container, Grid, Icon, Image } from 'semantic-ui-react';

import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';

import './contact.css';

import SocialMediaImage1 from '../images/social/facebook.png';
import SocialMediaImage2 from '../images/social/instagram.png';

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <Header />
    <div className="container__ket-blue myriad-regular">
      <Container text textAlign="center">
        We work directly with law enforcement, distributors, and retailers. If you are
        interested in working together, contact:
      </Container>
      <Container text textAlign="center">
        <a className="contact-email-link" href="mailto:sales@ket-us.com">
          sales@ket-us.com
        </a>
      </Container>
      <Container text textAlign="center">
        <Grid>
          <Grid.Row columns={7} centered={true}>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <a href="https://www.facebook.com/kineticenergytoolsllc/">
                <Image
                  alt="Visit us on Facebook"
                  size="mini"
                  src={SocialMediaImage1}
                  title="Visit us on Facebook"
                />
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <a href="https://www.instagram.com/kineticenergytools/">
                <Image
                  alt="Visit us on Instagram"
                  size="mini"
                  src={SocialMediaImage2}
                  title="Visit us on Instagram"
                />
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <a href="mailto:sales@ket-us.com">
                <Icon name="mail" size="large" />
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  </Layout>
);

export default Contact;
